var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "serviceSheetList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          headers: _vm.headers,
          isMultiSelect: true,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "订单编号" },
                  model: {
                    value: _vm.searchParams.serialNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "serialNumber", $$v)
                    },
                    expression: "searchParams.serialNumber",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "订单状态", options: _vm.orderStatusOps },
                  model: {
                    value: _vm.searchParams.orderStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orderStatus", $$v)
                    },
                    expression: "searchParams.orderStatus",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "业主姓名", placeholder: "请输入完整姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "业主手机", placeholder: "请输入完整手机号" },
                  model: {
                    value: _vm.searchParams.userPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userPhone", $$v)
                    },
                    expression: "searchParams.userPhone",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "业主地址" },
                      model: {
                        value: _vm.searchParams.houseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "houseId", $$v)
                        },
                        expression: "searchParams.houseId",
                      },
                    },
                    "v-select2",
                    _vm.houseParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "支付状态", options: _vm.isPayOps },
                  model: {
                    value: _vm.searchParams.isPay,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isPay", $$v)
                    },
                    expression: "searchParams.isPay",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "订单类型", options: _vm.payCategoryOps },
                  model: {
                    value: _vm.searchParams.payCategory,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "payCategory", $$v)
                    },
                    expression: "searchParams.payCategory",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属组织" },
                      model: {
                        value: _vm.searchParams.orgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgId", $$v)
                        },
                        expression: "searchParams.orgId",
                      },
                    },
                    "v-select2",
                    _vm.orgParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "优先处理", options: _vm.firstDealOps },
                  model: {
                    value: _vm.searchParams.caseType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "caseType", $$v)
                    },
                    expression: "searchParams.caseType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属服务", subjoin: _vm.subExtraParams },
                      on: { onChange: _vm.subChange },
                      model: {
                        value: _vm.searchParams.subId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "subId", $$v)
                        },
                        expression: "searchParams.subId",
                      },
                    },
                    "v-select2",
                    _vm.subParams,
                    false
                  )
                ),
                _vm.orderNumberOps.length > 0 && _vm.searchParams.subId
                  ? _c("v-select", {
                      attrs: { label: "服务分类", options: _vm.orderNumberOps },
                      model: {
                        value: _vm.searchParams.orderNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orderNumber", $$v)
                        },
                        expression: "searchParams.orderNumber",
                      },
                    })
                  : _vm._e(),
                _c("v-select", {
                  attrs: { label: "支付方式", options: _vm.payTypeOps },
                  model: {
                    value: _vm.searchParams.payType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "payType", $$v)
                    },
                    expression: "searchParams.payType",
                  },
                }),
                _vm.searchParams.payType !== undefined
                  ? _c("v-datepicker", {
                      attrs: {
                        label: "支付时间",
                        startTime: _vm.searchParams.payStartTime,
                        endTime: _vm.searchParams.payEndTime,
                      },
                      on: {
                        "update:startTime": function ($event) {
                          return _vm.$set(
                            _vm.searchParams,
                            "payStartTime",
                            $event
                          )
                        },
                        "update:start-time": function ($event) {
                          return _vm.$set(
                            _vm.searchParams,
                            "payStartTime",
                            $event
                          )
                        },
                        "update:endTime": function ($event) {
                          return _vm.$set(
                            _vm.searchParams,
                            "payEndTime",
                            $event
                          )
                        },
                        "update:end-time": function ($event) {
                          return _vm.$set(
                            _vm.searchParams,
                            "payEndTime",
                            $event
                          )
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-select", {
                  attrs: { label: "工单来源", options: _vm.sourceTypeOps },
                  model: {
                    value: _vm.searchParams.sourceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sourceType", $$v)
                    },
                    expression: "searchParams.sourceType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-datepicker", {
                  attrs: {
                    label: "预约时间段",
                    startTime: _vm.searchParams.orderStartTime,
                    endTime: _vm.searchParams.orderEndTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "orderStartTime",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "orderStartTime",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "orderEndTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "orderEndTime", $event)
                    },
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "提交时间段",
                    startTime: _vm.searchParams.referStartTime,
                    endTime: _vm.searchParams.referEndTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "referStartTime",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "referStartTime",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "referEndTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "referEndTime", $event)
                    },
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "是否售后", options: _vm.isAfterSalesIngOps },
                  model: {
                    value: _vm.searchParams.isAfterSalesIng,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isAfterSalesIng", $$v)
                    },
                    expression: "searchParams.isAfterSalesIng",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "是否回访", options: _vm.isReturnVisitOps },
                  model: {
                    value: _vm.searchParams.isReturnVisit,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isReturnVisit", $$v)
                    },
                    expression: "searchParams.isReturnVisit",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "录入人" },
                      model: {
                        value: _vm.searchParams.adminId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "adminId", $$v)
                        },
                        expression: "searchParams.adminId",
                      },
                    },
                    "v-select2",
                    _vm.adminParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "加签类型", options: _vm.isSignTypeOps },
                  on: { change: _vm.isSignTypeChange },
                  model: {
                    value: _vm.searchParams.isSignType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isSignType", $$v)
                    },
                    expression: "searchParams.isSignType",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "加签名称", options: _vm.countersignTypeOps },
                  model: {
                    value: _vm.searchParams.countersignType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "countersignType", $$v)
                    },
                    expression: "searchParams.countersignType",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                (scope.row.orderStatus === 1 &&
                  (scope.row.grantStatus == 1 || scope.row.grantStatus == 2)) ||
                scope.row.grantStatus == 0 ||
                scope.row.grantStatus == 3
                  ? _c(
                      "div",
                      [
                        _vm.detailView || _vm.orderDeal
                          ? _c("v-button", {
                              attrs: { text: _vm.editText, type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(scope.row)
                                },
                              },
                            })
                          : _vm._e(),
                        scope.row.orderStatus === 2
                          ? _c("v-button", {
                              attrs: {
                                permission: "feedback",
                                text: "添加处理中反馈",
                                type: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showFeedback(scope.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: "批量接单",
                    permission: "orderDeal",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batch(scope.selectedData, 6)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: "批量处理",
                    permission: "orderDeal",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batch(scope.selectedData, 2)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: "批量完成",
                    permission: "orderDeal",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batch(scope.selectedData, 3)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单进度",
            visible: _vm.dialogProgress,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogProgress = $event
            },
            close: _vm.dialogProgressClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "progress-textarea" },
                [
                  _c("v-textarea", {
                    attrs: { placeholder: "请输入处理反馈", width: 460 },
                    model: {
                      value: _vm.progressForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.progressForm, "remark", $$v)
                      },
                      expression: "progressForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "progress-upload" },
                [
                  _c("v-uploader", {
                    ref: "myUpload",
                    attrs: {
                      action: _vm.uploadUrl,
                      imgUrls: _vm.progressForm.file,
                      limit: 4,
                      fileWidth: 60,
                    },
                    on: {
                      "update:imgUrls": function ($event) {
                        return _vm.$set(_vm.progressForm, "file", $event)
                      },
                      "update:img-urls": function ($event) {
                        return _vm.$set(_vm.progressForm, "file", $event)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "v-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.submitProgress },
                        },
                        [_vm._v("提 交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-timeline",
                _vm._l(_vm.progressDetail, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index, staticClass: "pro-group" },
                    [
                      _c("div", { staticClass: "progress" }, [
                        _c("div", { staticClass: "progress-top" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(item.track)),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(_vm._s(item.time)),
                          ]),
                        ]),
                        _c("div", { staticClass: "progress-remark" }, [
                          _vm._v(_vm._s(item.remark)),
                        ]),
                        item.images.length > 0
                          ? _c(
                              "div",
                              { staticClass: "progress-view" },
                              [
                                _c("v-preview", {
                                  attrs: {
                                    imgs: item.images,
                                    imgStyle: _vm.previewStyle,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }