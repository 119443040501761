<template>
  <div class="serviceSheetList-wrapper">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :extraParams="extraParams"
          :headers="headers"
          :isMultiSelect="true">
      <template #headerSlot>
        <v-button text="新增"
                  permission="add"
                  @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="订单编号"
                 v-model="searchParams.serialNumber"></v-input>
        <v-select label="订单状态"
                  v-model="searchParams.orderStatus"
                  :options="orderStatusOps"></v-select>
        <v-input label="业主姓名"
                 v-model="searchParams.userName"
                 placeholder="请输入完整姓名"></v-input>
        <v-input label="业主手机"
                 v-model="searchParams.userPhone"
                 placeholder="请输入完整手机号"></v-input>
        <v-select2 label="业主地址"
                   v-model="searchParams.houseId"
                   v-bind="houseParams"></v-select2>
        <v-select label="支付状态"
                  v-model="searchParams.isPay"
                  :options="isPayOps"></v-select>
        <v-select label="订单类型"
                  v-model="searchParams.payCategory"
                  :options="payCategoryOps"></v-select>
        <v-select2 label="所属组织"
                   v-model="searchParams.orgId"
                   v-bind="orgParams"></v-select2>
        <v-select label="优先处理"
                  v-model="searchParams.caseType"
                  :options="firstDealOps"></v-select>
        <v-select2 label="所属服务"
                   v-model="searchParams.subId"
                   v-bind="subParams"
                   :subjoin="subExtraParams"
                   @onChange="subChange"></v-select2>
        <v-select label="服务分类"
                  v-if="orderNumberOps.length > 0 && searchParams.subId"
                  v-model="searchParams.orderNumber"
                  :options="orderNumberOps"></v-select>
        <v-select label="支付方式"
                  v-model="searchParams.payType"
                  :options="payTypeOps"></v-select>
        <v-datepicker v-if="searchParams.payType !== undefined"
                      label="支付时间"
                      :startTime.sync="searchParams.payStartTime"
                      :endTime.sync="searchParams.payEndTime"></v-datepicker>
        <v-select label="工单来源"
                  v-model="searchParams.sourceType"
                  :options="sourceTypeOps"></v-select>
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-datepicker label="预约时间段"
                      :startTime.sync="searchParams.orderStartTime"
                      :endTime.sync="searchParams.orderEndTime"></v-datepicker>
        <v-datepicker label="提交时间段"
                      :startTime.sync="searchParams.referStartTime"
                      :endTime.sync="searchParams.referEndTime"></v-datepicker>
        <v-select2 label="所属公司"
                   v-model="searchParams.regionId"
                   v-bind="regionParams"></v-select2>
        <v-select label="是否售后"
                  v-model="searchParams.isAfterSalesIng"
                  :options="isAfterSalesIngOps"></v-select>
        <v-select label="是否回访"
                  v-model="searchParams.isReturnVisit"
                  :options="isReturnVisitOps"></v-select>
        <v-select2 label="录入人"
                   v-model="searchParams.adminId"
                   v-bind="adminParams"></v-select2>
        <v-select label="加签类型"
                  v-model="searchParams.isSignType"
                  :options="isSignTypeOps"
                  @change="isSignTypeChange"></v-select>
        <v-select label="加签名称"
                  v-model="searchParams.countersignType"
                  :options="countersignTypeOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <div v-if="(scope.row.orderStatus === 1 && (scope.row.grantStatus == 1 || scope.row.grantStatus == 2)) || scope.row.grantStatus == 0 || scope.row.grantStatus == 3">
          <v-button v-if="detailView || orderDeal"
                    :text="editText"
                    type="text"
                    @click="edit(scope.row)"></v-button>
          <v-button v-if="scope.row.orderStatus === 2"
                    permission="feedback"
                    text="添加处理中反馈"
                    type="text"
                    @click="showFeedback(scope.row)"></v-button>
        </div>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量接单"
                  permission="orderDeal"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData, 6)"></v-button>
        <v-button text="批量处理"
                  permission="orderDeal"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData, 2)"></v-button>
        <v-button text="批量完成"
                  permission="orderDeal"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData, 3)"></v-button>
      </template>
    </list>
    <el-dialog title="订单进度"
               :visible.sync="dialogProgress"
               width="500px"
               @close="dialogProgressClose">
      <div>
        <div class="progress-textarea">
          <v-textarea v-model="progressForm.remark"
                      placeholder="请输入处理反馈"
                      :width="460"></v-textarea>
        </div>
        <div class="progress-upload">
          <v-uploader ref="myUpload"
                      :action="uploadUrl"
                      :imgUrls.sync="progressForm.file"
                      :limit="4"
                      :fileWidth="60">
          </v-uploader>
          <div class="right">
            <v-button type="success"
                      @click="submitProgress">提 交</v-button>
          </div>
        </div>
        <el-timeline>
          <el-timeline-item class="pro-group"
                            v-for="(item, index) in progressDetail"
                            :key="index">
            <div class="progress">
              <div class="progress-top">
                <span class="left">{{item.track}}</span>
                <span class="right">{{item.time}}</span>
              </div>
              <div class="progress-remark">{{item.remark}}</div>
              <div class="progress-view"
                   v-if="item.images.length > 0">
                <v-preview :imgs="item.images"
                           :imgStyle="previewStyle"></v-preview>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  batchURL,
  getUserRoomsURL,
  getViewSubURL,
  getAdminsURL,
  getPayTypeListURL,
  queryTaskListURL,
  uploadURL,
  addFeedbackInfoURL,
  getOrderLabelNoPageListURL,
  querySignTypeURL,
  queryServiceIDURL,
  getServiceCategoryURL
} from './api'
import {
  orderStatusOps,
  orderStatusMap,
  isPayOps,
  isPayMap,
  payCategoryOps,
  payCategoryMap,
  firstDealOps,
  isAfterSalesIngOps,
  isReturnVisitOps,
  isSignTypeOps
} from './map'
import { generateMapByOpts } from 'common/utils'
import { vPreview, vUploader } from 'components/control'
import { Timeline, TimelineItem } from 'element-ui'
import { createAlinkVNode } from 'common/vdom'
import { orgParams, communityParams, regionParams } from 'common/select2Params'
export default {
  name: 'ServiceSheetList',
  components: {
    vPreview,
    vUploader,
    elTimeline: Timeline,
    elTimelineItem: TimelineItem
  },
  data () {
    let _this = this
    return {
      orderStatusOps,
      isPayOps,
      payCategoryOps,
      firstDealOps,
      sourceTypeOps: [],
      sourceTypeMap: {},
      isAfterSalesIngOps,
      isReturnVisitOps,
      isSignTypeOps,
      orgParams,
      communityParams,
      regionParams,
      uploadUrl: uploadURL,
      payTypeOps: [],
      payTypeMap: {},
      orderNumberOps: [],
      countersignTypeOps: [],
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: [
        {
          text: '全部',
          value: undefined
        }, {
          text: '开启',
          value: 1
        }, {
          text: '关闭',
          value: 0
        }
      ],
      houseParams: {
        searchUrl: getUserRoomsURL,
        request: {
          text: 'roomNum',
          value: 'houseId'
        }
      },
      subParams: {
        searchUrl: getViewSubURL,
        request: {
          text: 'subName',
          value: 'subId'
        }
      },
      adminParams: {
        searchUrl: getAdminsURL,
        request: {
          text: 'userName',
          value: 'adminId'
        }
      },
      searchParams: {
        serialNumber: '',
        orderStatus: undefined,
        userName: '',
        userPhone: '',
        houseId: '',
        isPay: undefined,
        payCategory: undefined,
        orgId: '',
        caseType: undefined,
        subId: '',
        orderNumber: undefined,
        payType: undefined,
        payStartTime: '',
        payEndTime: '',
        sourceType: undefined,
        communityId: '',
        orderStartTime: '',
        orderEndTime: '',
        referStartTime: '',
        referEndTime: '',
        regionId: '',
        isAfterSalesIng: undefined,
        isReturnVisit: undefined,
        adminId: '',
        isSignType: undefined,
        countersignType: undefined,
        subCode: ''
      },
      extraParams: {
        startDealTime: '',
        endDealTime: '',
        orderStatusList: ''
      },

      headers: [
        {
          prop: 'serialNumberNode',
          label: '订单编号',
          formatter (row) {
            let h = _this.$createElement
            return h('span', {
              domProps: {
                innerText: row.serialNumber
              },
              style: {
                color: '#1b8cf2',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  _this.edit(row)
                }
              }
            })
          }
        },
        {
          prop: 'userName',
          label: '用户姓名'
        },
        {
          prop: 'userPhone',
          label: '用户手机'
        },
        {
          prop: 'userAddress',
          label: '住址信息'
        },
        {
          prop: 'referTime',
          label: '提交时间'
        },
        {
          prop: 'orderTime',
          label: '预约时间'
        },
        {
          prop: 'subName',
          label: '所属服务'
        },
        {
          prop: 'orderStatusText',
          label: '状态',
          formatter (row) {
            return orderStatusMap[row.orderStatus]
          }
        },
        {
          prop: 'totalMoney',
          label: '订单金额',
          align: 'right'
        },
        {
          prop: 'payTypeText',
          label: '支付方式',
          formatter (row) {
            return _this.payTypeMap[row.payType]
          }
        },
        {
          prop: 'isPayText',
          label: '支付状态',
          formatter (row) {
            let h = _this.$createElement
            return h('span', {
              domProps: {
                innerHTML: `${isPayMap[row.isPay]}<br /> ${row.onlinePaidTime}`
              }
            })
          }
        },
        {
          prop: 'orgName',
          label: '所属组织'
        },
        {
          prop: 'payCategoryText',
          label: '订单类型',
          formatter (row) {
            return payCategoryMap[row.payCategory]
          }
        },
        {
          prop: 'sourceTypeText',
          label: '工单来源',
          formatter: (row, prop) => {
            if (row.sourceType === 1 || row.sourceType === 2) {
              let opts = {
                cb: _this.showUser,
                text: _this.sourceTypeMap[row.sourceType]
              }
              return createAlinkVNode(this, row, prop, opts)
            } else {
              return _this.sourceTypeMap[row.sourceType]
            }
          }
        },
        {
          prop: 'regionName',
          label: '所属公司'
        }
      ],
      batchText: '您的订单已接收，稍后将安排工作人员为您服务。',
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      },
      previewStyle: {
        width: '60px',
        height: '60px',
        marginRight: '10px'
      },
      selectCaseId: '',
      dialogProgress: false,
      progressDetail: [],
      progressForm: {
        remark: '',
        file: []
      }
    }
  },
  async created () {
    const { orgId, communityId, orderStatus } = this.$route.query
    if (orgId) {
      this.searchParams.orgId = orgId
    }
    if (communityId) {
      this.searchParams.communityId = communityId
    }
    if (orderStatus) {
      this.searchParams.orderStatus = Number(orderStatus)
    }
    this.searchParams.subCode= this.$store.state.userInfo.regionType == '3'? '9300' : ''
    // await this.$store.dispatch('mapStore/queryPayType')
    // const { setPayTypeOps, payTypeMap } = this.$store.getters['mapStore/getPayType']()
    // this.payTypeOps = setPayTypeOps(1)
    // this.payTypeMap = payTypeMap

    // 处理跳转过来带的参数
    this.dealWithRouteParams()
    await this.$store.dispatch('mapStore/queryOrderSource')
    const { setOrderSourceOps, orderSourceMap } = this.$store.getters['mapStore/getOrderSource']()
    this.sourceTypeOps = setOrderSourceOps(1)
    this.sourceTypeMap = orderSourceMap
    this.querySignType()
  },
  computed: {
    detailView () { return this.$store.getters.getPermission('detailView') },
    orderDeal () { return this.$store.getters.getPermission('orderDeal') },
    editText () {
      return this.detailView && !this.orderDeal ? '查看' : '编辑'
    },
    subExtraParams () {
      return {
        subCode: this.$store.state.userInfo.regionType == '3'? '9300' : undefined
      }
    }
  },
  mounted () {
    this.getPayTypeOps()
    if (this.searchParams.isSignType) {
      this.isSignTypeChange(this.searchParams.isSignType)
    }
    // 处理跳转过来带的参数
    // this.dealWithRouteParams()
  },
  methods: {
    // 处理跳转过来的数据
    dealWithRouteParams () {
      const { fromPage } = this.$route.query
      if (fromPage === undefined) {
        return false
      }
      //  这个处理是防止跳转带的参数，被之前本地缓存参数污染
      this.searchParams = {
        serialNumber: '',
        orderStatus: undefined,
        userName: '',
        userPhone: '',
        houseId: '',
        isPay: undefined,
        payCategory: undefined,
        orgId: '',
        caseType: undefined,
        subId: '',
        orderNumber: undefined,
        payType: undefined,
        payStartTime: '',
        payEndTime: '',
        sourceType: undefined,
        communityId: '',
        subCommunityId: '',
        orderStartTime: '',
        orderEndTime: '',
        referStartTime: '',
        referEndTime: '',
        regionId: '',
        isAfterSalesIng: undefined,
        isReturnVisit: undefined,
        adminId: '',
        isSignType: undefined,
        countersignType: undefined
      }

      const query = this.$route.query

      // 订单状态
      if (query.orderStatus !== undefined) {
        this.searchParams.orderStatus = parseInt(query.orderStatus)
      }
      //  提交时间段： 今日往前90天~今日
      if (query.referStartTime !== undefined) {
        this.searchParams.referStartTime = query.referStartTime
      }
      //  提交时间段： 今日往前90天~今日
      if (query.referEndTime !== undefined) {
        this.searchParams.referEndTime = query.referEndTime
      }
      //  是否回访
      if (query.isReturnVisit !== undefined) {
        this.searchParams.isReturnVisit = parseInt(query.isReturnVisit)
      }
      //  优先处理
      if (query.caseType !== undefined) {
        this.searchParams.caseType = parseInt(query.caseType)
      }

      if (query.startDealTime !== undefined) {
        this.extraParams.startDealTime = query.startDealTime
      }

      if (query.endDealTime !== undefined) {
        this.extraParams.endDealTime = query.endDealTime
      }

      if (query.orderStatusList !== undefined) {
        this.extraParams.orderStatusList = query.orderStatusList
      }
    },
    getPayTypeOps () {
      this.$axios.get(getPayTypeListURL).then(res => {
        if (res.status === 100) {
          const { data } = res
          let payTypeOps = data.map(item => {
            return {
              text: item.payTypeDesc,
              value: item.payType
            }
          })
          this.payTypeOps = [
            {
              text: '全部',
              value: undefined
            },
            ...payTypeOps
          ]
          this.payTypeMap = generateMapByOpts(payTypeOps)
        }
      })
    },
    updateStatus (ids, status) {
      let _this = this
      let _ids = ids.join(',')
      let postData = {
        orderIds: _ids,
        orderStatus: status,
        taskMemo: this.batchText
      }
      if (status === 3) {
        postData.operationDes = this.batchText
        postData.operaType = 'done'
      }
      _this.$axios.post(batchURL, this.$qs.stringify(postData), this.postHeader).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    batch (selected, status) {
      let ids = selected.ids
      const h = this.$createElement
      if (status === 6) {
        this.batchText = '您的订单已接收，稍后将安排工作人员为您服务。'
      } else if (status === 2) {
        this.batchText = '您的订单已开始处理，请关注。'
      } else if (status === 3) {
        this.batchText = '服务已完毕，请为本次服务打分/评价，祝生活幸福!'
      }
      this.$confirm('', {
        title: '反馈信息',
        type: 'aa',
        message: h('v-textarea', {
          props: {
            value: this.batchText,
            width: 395
          },
          style: {
            marginBottom: '20px'
          },
          on: {
            input: (event) => {
              this.batchText = event
              this.$emit('input', event)
            }
          }
        })
      }).then((isOk) => {
        isOk && this.updateStatus(ids, status)
      })
    },
    create () {
      this.$router.push({
        name: 'serviceSheetForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'serviceSheetForm',
        query: {
          id: row.id,
          orderCode: row.serialNumber,
          communityId: row.communityId,
          subCommunityId: row.subCommunityId
        }
      })
    },
    showUser (row) {
      const h = this.$createElement
      this.$confirm('', {
        title: '提交人信息',
        type: 'infos',
        message: h('div', {
          style: {
            textAlign: 'center',
            lineHeight: '30px'
          }
        }, [
          h('div', {
            domProps: {
              innerText: `姓名：${row.inuserName}`
            }
          }),
          h('div', {
            domProps: {
              innerText: `手机：${row.inuserMobileNum}`
            }
          })
        ]),
        showClose: true,
        showCancelButton: false,
        showConfirmButton: false,
        center: true
      })
    },
    showFeedback (row) {
      this.selectCaseId = row.id
      this.$axios.get(queryTaskListURL, { params: {
        caseId: row.id
      } }).then(res => {
        if (res.status === 100) {
          this.progressDetail = res.data ? res.data : []
          this.dialogProgress = true
        }
      })
    },
    dialogProgressClose () {
      this.progressForm.remark = ''
      this.progressForm.file = []
    },
    submitProgress () {
      if (!this.progressForm.remark) {
        this.$message({
          type: 'error',
          message: '请添加工单处理过程中的阶段性成果',
          center: true
        })
        return false
      }
      let data = {
        file: JSON.stringify(this.progressForm.file.map(url => { return { url } })),
        remark: this.progressForm.remark,
        caseId: this.selectCaseId,
        taskStatus: 2
      }
      this.$axios.post(addFeedbackInfoURL, data, this.postHeader).then(res => {
        if (res.status === '100') {
          this.dialogProgress = false
          this.$message({
            type: 'success',
            message: '反馈提交成功',
            center: true
          })
        }
      })
    },
    isSignTypeChange (val) {
      if (val) {
        this.$axios.get(`${getOrderLabelNoPageListURL}?categoryId=${val}`).then(res => {
          if (res.status === 100) {
            let data = res.data
            this.countersignTypeOps = [
              {
                text: '全部',
                value: undefined
              },
              ...data.map(item => {
                return {
                  text: item.labelName,
                  value: item.id
                }
              })
            ]
            this.searchParams.countersignType = undefined
          }
        })
      } else {
        this.countersignTypeOps = [
          {
            text: '全部',
            value: undefined
          }
        ]
      }
    },
    subChange (val, isBack) {
      // if (val && val.name) {
      //   let subOpsType = subBackMap[val.name]
      //   if (subOpsType !== undefined) {
      //     this.orderNumberOps = orderNumberOps[subOpsType]
      //   } else {
      //     this.orderNumberOps = []
      //   }
      // }
      if (!isBack) {
        this.searchParams.orderNumber = undefined
      }
      if (val && val.subCode) {
        this.getServiceCategory(val.subCode)
      } else {
        this.orderNumberOps = []
      }
    },
    async querySignType () {
      let res = await this.$axios.get(queryServiceIDURL)
      let { data } = await this.$axios.get(querySignTypeURL, { params: { parentId: res.data } })
      this.isSignTypeOps = data.map(item => ({ text: item.categoryName, value: item.id }))
      this.isSignTypeOps.unshift({
        text: '全部',
        value: undefined
      })
    },

    async getServiceCategory (subCode) {
      let params = {
        subCode: subCode
      }
      const { data, status } = await this.$axios.get(getServiceCategoryURL, { params })
      let list = []
      if (status === 100) {
        list = data || []
        list = list.map(item => ({ text: item.name, value: item.id }))
      }
      if (list.length > 0) {
        list.unshift({
          text: '全部',
          value: undefined
        })
      }
      this.orderNumberOps = list
    }
  }
}
</script>
<style lang="scss" scoped>
.progress {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-left: -23px;
  padding-left: 23px;
  .progress-top {
    display: flex;
    justify-content: space-between;
    line-height: 28px;
    border-bottom: 1px solid #ccc;

    .left {
      color: #1b8cf2;
    }
    .right {
    }
  }
  .progress-remark {
    margin: 15px 0;
  }
  .progress-view {
  }
}
.pro-group:last-child {
  .progress {
    border: none;
  }
}
.rates {
  text-align: left;
  margin-top: 10px;
}
.progress-textarea {
  margin-bottom: 20px;
}
.progress-upload {
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .right {
    padding-top: 15px;
  }
}
</style>
